.banner{
    background: url("../../images/background1.jpg") no-repeat 100%;
    background-size: cover;
    height: 600px;
    background-position: 0 0;  
    margin-bottom: 100px;
}
.banner .container {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
@media (max-width: 768px){
    .banner {
        height: auto;
        padding: 80px 20px;
    }
    .banner .container{
        align-items: center;
    }   
} 