.how-content{
    background: url("../../images/path.png") no-repeat 50%;
    height: 1100px;
    /* background-size: cover; */
    position: relative;
    margin-top: 40px;
}
.how-content-bottom{
    position: absolute;
    right: 0;
    bottom: 0;
}
.how-content-top{
    position: absolute;
    left: 0;
    top: 0;
}
.how-content-list{
    height: 100%;
   padding-top: 60px;
}
.how-content-item{
    text-align: center;
    margin-bottom: 35px;
}
.how-content-item-text{
    max-width: 40%;
    margin: 0 auto;
}
@media (max-width: 1200px){
    .how-content-bottom, .how-content-top{
        width: 30%;
    }
} 
@media (max-width: 992px){
    .how-content{
        background: none;
        height: auto;
    }
    .how-content-bottom, .how-content-top{
       display: none;
    }
} 