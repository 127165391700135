

@font-face{
  font-family: 'SourceSansPro';
  src: url("fonts/SourceSansPro-Regular.ttf") format('truetype');
  font-weight: normal;   
  font-style: normal;  
}
@font-face{
  font-family:'SourceSansPro';
  src: url('fonts/SourceSansPro-Black.ttf') format('truetype');
  font-weight: bold;   
  font-style: normal;  
}
@font-face{
  font-family:'SourceSansPro';
  src: url('fonts/SourceSansPro-Italic.ttf') format('truetype');
  font-weight: normal;   
  font-style: italic;  
} 

* {
  transition: all 1s;
  font-family: 'SourceSansPro';
}
.mt{
  margin-top: 10px;
}
.mt-text{
  font-size: 16px;
}
body.modal-open{
  padding-right: 0!important;
}
p{
  font-family: "SourceSansPro";
  font-size: 18px;
  letter-spacing: 0.8px;
  text-align: center;
  color: #000000;
  line-height: 1.33;
  margin-bottom: 0;
}
.container{
  padding: 0;
}
.content-btn{
  width: 340px;
  height: 60px;
  border-radius: 35px;
  background-color: #ff5d5d;
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.2);
  background-image: none!important; 
  font-size: 20px;
  letter-spacing: 0.8px;
  text-align: center;
  color: #fff;
  border: none;
  text-transform: uppercase;
}
.content-header, .content-subheader{
  
  font-size: 48px;
  font-weight: bold;
  letter-spacing: 2px;
  color: #ffffff;
  text-transform: uppercase;
  margin-bottom: 4px;
  margin-top: 0;
}
.content-subheader{
  font-size: 24px;
  letter-spacing: 2.5px;
  text-transform: none;
  font-weight: normal;
  margin-bottom: 30px;
}

.content-btn:hover, .content-btn:active, .content-btn:focus, .content-btn:active:hover{
  background-color: #ff4c4c;
  color: #fff;
}
.form-input{
  width: 370px!important;
  border-radius: 20px;
  box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.4);
  background-color: #ffffff;
  border: none;
  padding: 15px 25px;
  min-height: 50px;
  font-size: 18px;
}

.error-form-text{
  font-size: 16px;
  letter-spacing: 0.7px;
  color: #ff5d5d;
  text-align: left; 
  margin-left: 8px;
}
.error-form{
  display: flex;
  align-items: center;
  margin-top: 13px;
}

@media (max-width: 768px){
  .content-header,  .content-subheader{
      font-size: 42px;
      text-align: center;
      margin: 15px auto;
  }  
  .content-subheader {
    font-size: 22px;
  }
} 
@media (max-width: 480px){
  .content-btn{
    width: 270px!important;
  }
  .form-input{
    width: 270px!important;
  }
}
