.header_section{
    text-align: center;
}
.header_section-text{
    font-size: 32px;
    font-weight: bold;
    letter-spacing: 1.3px;
    color: #493b6e;
    margin: 5px auto;
    text-transform: uppercase;
}